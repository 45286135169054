.coin-card {
  $padding: 0.5rem;

  width: calc(100% - 4 * #{$padding});
  padding: $padding;
  margin: $padding;
  border-radius: 8px;
  background: linear-gradient(to left, $col-hydra-dark, $col-hydra-black);

  img {
    height: 3rem;
  }

  .name {
    line-height: 3rem;
    margin-left: 0.5rem;
  }

  .price {
    line-height: 3rem;
    font-weight: bold;
  }
}

// to promote in-app features
.advert-card {
  $padding: 0.5rem;

  width: calc(100% - 4 * #{$padding});
  padding: $padding;
  margin: $padding;
  border-radius: 8px;
  min-height: 6rem;
}

.asset-card {
  $padding: 0.5rem;

  width: calc(100% - 4 * #{$padding});
  padding: $padding;
  margin: $padding;
  border-radius: 8px;
  min-height: 6rem;
}

.product-card {
  $padding: 0.5rem;

  width: calc(100% - 4 * #{$padding});
  padding: $padding;
  margin: $padding;
  border-radius: 8px;
  min-height: 6rem;
  background-color: #333;
}

.portfolio-holdings {
  @extend .card;
  $padding: 1rem;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  width: calc(100vw - 2 * #{$padding});
  height: 12rem;
  padding: $padding;
  background: #1118;
  backdrop-filter: blur(7px);
  color: white;
  overflow: hidden;
}
