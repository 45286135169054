.card {
  position: relative;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0), 0 1px 2px rgba(0, 0, 0, 0);

  &:hover {
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.25), 0 3px 3px rgba(0, 0, 0, 0.22);
    transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  }

  &-1 {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }

  &-2 {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  &-3 {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }

  &-4 {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }

  &-5 {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  }
}

$stack-shadow: 0 -2px 10px 1px rgba(50, 50, 50, 0.5);

.card-stack {
  .parent {
    box-shadow: $stack-shadow;
  }

  @mixin stacker($padding) {
    position: absolute;
    width: calc(100% - 2 * #{$padding});

    margin: {
      left: $padding;
      right: $padding;
    }

    box-shadow: $stack-shadow;
    background-color: #fff;
  }

  .stack {
    &-1 {
      @include stacker(0.5rem);
    }

    &-2 {
      @include stacker(1rem);
    }

    &-3 {
      @include stacker(1.5rem);
    }
  }

  .loader-background {
    position: absolute;
    top: -2px;
    left: -2px;
    background-color: rgba(0, 0, 0, 0.4);
  }
}

.card-stack-child {
  @extend .card;
  @extend .card-1;
  // box-shadow: $stack-shadow;
}
