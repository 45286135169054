.no-scrollbar {

  /* IE and Edge */
  -ms-overflow-style: none;

  /* Firefox */
  scrollbar-width: none;
}

.no-scrollbar::-webkit-scrollbar {

  /* Chrome, Safari and Opera */
  display: none;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.auto-scroll-x {
  overflow: hidden;

  &:hover {
    overflow-x: auto;
  }
}

.auto-scroll-y {
  overflow: hidden;

  &:hover {
    overflow-y: auto;
  }

  height: 100%;
}

.text-center {
  text-align: center;
}

.align-center {
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.space-evenly {
  justify-content: space-evenly;
}

.space-around {
  justify-content: space-around;
}

.fill-container {
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 2rem;
  min-height: 2rem;
}

.full-screen {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.full-width {
  position: relative;
  width: 100%;

  &-padded {
    width: calc(100% - 2rem);
  }

  &.padded {
    width: calc(100% - 2rem);
  }
}

.full-height {
  height: 100vh;
}

.w-fit-content {
  width: fit-content;
}

.centered {
  // width: fit-content;
  margin: auto;
}

.padded {
  padding: 0.5rem;
  width: calc(100% - 1rem);
}

.margined {
  margin: auto;
  max-width: 50rem;
}

.floating-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: fit-content;
}

.mx-auto {
  margin: {
    left: auto;
    right: auto;
  }
}
