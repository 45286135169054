$navbar-size: 56px;
$navbar-big: 16rem;

$breakpoint-tablet: 688px;
$breakpoint-desktop: 1080px;
$breakpoint-mobile: 480px;

.mobile-navbar {
  @include hideAbove($breakpoint-mobile);

  @extend .flex-row;

  justify-content: space-between;
  position: fixed;
  z-index: 10;
  background-color: #0008;
  backdrop-filter: blur(5px);
  color: red;
  width: 100vw;
  height: 3rem;
  bottom: 0;
  left: 0;
  box-shadow: 0 3px 10px rgba(black, 0.5);

  .main-tab {
    background-color: $col-hydra-blue;
    cursor: pointer;
    color: $col-hydra-blue;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    margin-top: -0.5rem;
    padding: 0.5rem;
  }

  .normal-tab {
    margin-top: 0.25rem;
    cursor: pointer;
    margin-left: 1rem;
    margin-right: 1rem;
    padding: 0.5rem;
    width: 1rem;
    height: 1rem;
  }

  .label {
    display: none;
  }
}

.desktop-navbar {
  @include hideBelow($breakpoint-mobile);

  @extend .flex-col;
  @extend .space-around;

  flex-grow: 1;
  position: relative;
  height: 100vh;
  width: $navbar-size;
  overflow: hidden;
  overflow-y: auto;
  background-color: #111;
  color: #666;
  box-shadow: 3px 0 29px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 3px 0 29px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 3px 0 29px 0 rgba(0, 0, 0, 0.2);

  @media (min-width: $breakpoint-desktop) and (max-width: 1000000px) {
    width: $navbar-big;
  }

  transition: 0.3s all ease-in-out;

  &.expanded {
    width: 16rem;
    transition: 0.3s all ease-in-out;
  }

  .label {
    width: 100%;

    @include hideBelow($breakpoint-mobile);

    height: $navbar-size;
    line-height: $navbar-size;
    padding-left: 1rem;
  }

  .item,
  .normal-tab,
  .main-tab {
    width: 100%;
    min-height: $navbar-size;
    text-align: center;
    line-height: $navbar-size;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    span.fa {
      width: 1.2rem;
      margin-top: 1.3rem;
      margin-left: 1rem;
      margin-right: 1.1rem;
    }

    &:hover {
      background-color: rgba($col-active, 0.05);
      color: $col-active;
    }

    img {
      margin-left: 0.6rem;
      margin-right: 0.75rem;
      margin-top: 0.75rem;
      width: 2rem;
      height: calc(0.6 * #{$navbar-size});
    }

    &.active {
      border-left: 1px solid $col-active;
      transition: 1s height ease-in-out;
      transition: all 0.3s ease-in-out;
    }

    .children {
      @extend .flex-col;

      background-color: rgba(black, 0.3);
      width: 100%;
      height: 0;
      min-height: 0;
      // max-height: 0px;
      overflow: hidden;
      transition: 1s all ease-in-out;

      &.expanded {
        transition: 1s all ease-in-out;
        min-height: auto;
        height: auto;
      }

      .child {
        width: calc(100% - 2rem);
        font-size: 0.75rem;
        margin-left: 1rem;

        span.fa {
          margin-right: 1rem;
        }

        // background-color: rgba(black, 0.3);
        color: #666;

        &.active {
          // color: white;
          font-weight: bold;
          transition: all 0.3s ease-in-out;
        }

        &:hover {
          cursor: pointer;
          color: $col-active;
        }
      }
    }
  }

  .active {
    color: $col-active;
    // border-left: 2px solid $col-active;
  }
}
