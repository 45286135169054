$col-hydra-black: #202020;
$col-hydra-dark: #303030;
$col-hydra-blue: #006995;
$col-hydra-cyan: #0091ad;
$col-hydra-sky: #3ac9e6;
$col-angelic-purple: #8e0ea4;

$col-active: $col-hydra-blue;
$col-primary: $col-active;
$col-secondary: #aaa;
$col-success: #3adb76;
$col-warning: #ffae00;
$col-error: #e94b35;

$col-lightgrey: #eeeef3;
$col-text: #888;
$col-white: #fff;

.gradient-background {
  background: linear-gradient(to bottom, $col-hydra-blue, $col-angelic-purple);
}
