.tabs {
  position: relative;
  width: 100%;
  // height: 2rem;
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  border-bottom: 1px solid rgba(grey, 0.3);
  margin-bottom: 1rem;

  .tab {
    // flex-grow: 1;
    padding: calc(1rem - 1px);
    // padding-right: 1rem;
    // height: 2rem;
    // line-height: 2rem;
    border-bottom: 2px solid transparent;
    text-align: center;
    font-size: 0.8rem;
    cursor: pointer;

    &.active {
      color: $col-active;
      border-bottom: 2px solid $col-active;
    }
  }
}
