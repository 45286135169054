@mixin hideAbove($breakpoint) {
  @media (min-width: 0) and (max-width: $breakpoint) {
    display: flex;
  }

  @media (min-width: $breakpoint) and (max-width: 1000000px) {
    display: none;
  }
}

@mixin hideBelow($breakpoint) {
  @media (min-width: 0) and (max-width: $breakpoint) {
    display: none;
  }

  @media (min-width: $breakpoint) and (max-width: 1000000px) {
    display: flex;
  }
}
